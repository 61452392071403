export const getRowAsAnObject = (row) => {
    return row.cell.reduce(
        (acc, cell) => ({
            ...acc,
            [cell.t]: cell.value || "",
        }),
        {}
    );
};

export const mockPrinters = [
    {
        id: "1",
        wspf_10_bcp_group: "XAG-TL",
        wspf_10_bcp_printer: "117-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "2",
        wspf_10_bcp_group: "XAG-OP",
        wspf_10_bcp_printer: "117-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "3",
        wspf_10_bcp_group: "EN-EA",
        wspf_10_bcp_printer: "117-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "4",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "117-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "5",
        wspf_10_bcp_group: "XA-ADM",
        wspf_10_bcp_printer: "117-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "6",
        wspf_10_bcp_group: "CCS01",
        wspf_10_bcp_printer: "31-RCOR-HP",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "7",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "ABT-BBP12",
        wspf_10_bcp_default: "0",
    },
    {
        id: "8",
        wspf_10_bcp_group: "PLC",
        wspf_10_bcp_printer: "ABT-BBP12",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "9",
        wspf_10_bcp_group: "ABT-ADM",
        wspf_10_bcp_printer: "ABT-BBP12",
        wspf_10_bcp_default: "0",
    },
    {
        id: "10",
        wspf_10_bcp_group: "AC",
        wspf_10_bcp_printer: "AC-BBP33",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "11",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "AC-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "12",
        wspf_10_bcp_group: "AC+",
        wspf_10_bcp_printer: "AC-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "13",
        wspf_10_bcp_group: "AC-ES",
        wspf_10_bcp_printer: "AC-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "14",
        wspf_10_bcp_group: "AC-ER+",
        wspf_10_bcp_printer: "AC-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "15",
        wspf_10_bcp_group: "AC-CGS",
        wspf_10_bcp_printer: "AC-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "16",
        wspf_10_bcp_group: "AC-GUEST",
        wspf_10_bcp_printer: "AC-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "17",
        wspf_10_bcp_group: "AC-ER",
        wspf_10_bcp_printer: "AC-BBP33",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "18",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "B230TELABEL1",
        wspf_10_bcp_default: "0",
    },
    {
        id: "19",
        wspf_10_bcp_group: "LHC",
        wspf_10_bcp_printer: "B230TELABEL1",
        wspf_10_bcp_default: "0",
    },
    {
        id: "20",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "B252R10BBP12",
        wspf_10_bcp_default: "0",
    },
    {
        id: "21",
        wspf_10_bcp_group: "A-USER",
        wspf_10_bcp_printer: "B252R10BBP12",
        wspf_10_bcp_default: "0",
    },
    {
        id: "22",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "BBP11-183-1",
        wspf_10_bcp_default: "0",
    },
    {
        id: "23",
        wspf_10_bcp_group: "MSC-ST",
        wspf_10_bcp_printer: "BBP11-183-1",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "24",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "BBP11-183-2",
        wspf_10_bcp_default: "0",
    },
    {
        id: "25",
        wspf_10_bcp_group: "MSC-ST",
        wspf_10_bcp_printer: "BBP11-183-2",
        wspf_10_bcp_default: "0",
    },
    {
        id: "26",
        wspf_10_bcp_group: "XAG-OP",
        wspf_10_bcp_printer: "BBP33-284",
        wspf_10_bcp_default: "0",
    },
    {
        id: "27",
        wspf_10_bcp_group: "XAG-TL",
        wspf_10_bcp_printer: "BBP33-284",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "28",
        wspf_10_bcp_group: "XA-ADM",
        wspf_10_bcp_printer: "BBP33-284",
        wspf_10_bcp_default: "0",
    },
    {
        id: "29",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "BBP33-BE-BI",
        wspf_10_bcp_default: "0",
    },
    {
        id: "30",
        wspf_10_bcp_group: "BI-ADM",
        wspf_10_bcp_printer: "BBP33-BE-BI",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "31",
        wspf_10_bcp_group: "MO-IC+",
        wspf_10_bcp_printer: "BBP33-BE-BI",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "32",
        wspf_10_bcp_group: "SFBADM",
        wspf_10_bcp_printer: "BBP33FB",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "33",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "BRADYFLEX1",
        wspf_10_bcp_default: "0",
    },
    {
        id: "34",
        wspf_10_bcp_group: "FS-ADM",
        wspf_10_bcp_printer: "BRADYFLEX1",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "35",
        wspf_10_bcp_group: "CV-COM",
        wspf_10_bcp_printer: "CV-BBP31",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "36",
        wspf_10_bcp_group: "CV-CO",
        wspf_10_bcp_printer: "CV-BBP31",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "37",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "CV-BBP31",
        wspf_10_bcp_default: "0",
    },
    {
        id: "38",
        wspf_10_bcp_group: "CV-ADM",
        wspf_10_bcp_printer: "CV-BBP31",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "39",
        wspf_10_bcp_group: "CV-CIN",
        wspf_10_bcp_printer: "CV-BBP33",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "40",
        wspf_10_bcp_group: "CV-COM",
        wspf_10_bcp_printer: "CV-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "41",
        wspf_10_bcp_group: "CV-ADM",
        wspf_10_bcp_printer: "CV-BBP33",
        wspf_10_bcp_default: "0",
    },
    {
        id: "42",
        wspf_10_bcp_group: "CV-ADM",
        wspf_10_bcp_printer: "CV-ZM400",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "43",
        wspf_10_bcp_group: "CV-CGS",
        wspf_10_bcp_printer: "CV-ZM400",
        wspf_10_bcp_default: "-1",
    },
    {
        id: "44",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "CV-ZM400",
        wspf_10_bcp_default: "0",
    },
    {
        id: "45",
        wspf_10_bcp_group: "CV-CO1",
        wspf_10_bcp_printer: "CV-ZM400",
        wspf_10_bcp_default: "0",
    },
    {
        id: "46",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "DYMO-01",
        wspf_10_bcp_default: "0",
    },
    {
        id: "47",
        wspf_10_bcp_group: "LHC",
        wspf_10_bcp_printer: "DYMO-01",
        wspf_10_bcp_default: "0",
    },
    {
        id: "48",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "DYMO-02",
        wspf_10_bcp_default: "0",
    },
    {
        id: "49",
        wspf_10_bcp_group: "R5",
        wspf_10_bcp_printer: "DYMO-03",
        wspf_10_bcp_default: "0",
    },
    {
        id: "50",
        wspf_10_bcp_group: "XAG-TL",
        wspf_10_bcp_printer: "EN-EA-BBP33",
        wspf_10_bcp_default: "0",
    },
];

export const mockTemplates = [
    {
        id: "1",
        wspf_10_bct_group: "ABT-FSU",
        wspf_10_bct_template: "ABT-P-94x31",
        wspf_10_bct_entity: "Part",
        wspf_10_bct_default: "-1",
    },
    {
        id: "2",
        wspf_10_bct_group: "ABT-ADM",
        wspf_10_bct_template: "ABT-P-94x31",
        wspf_10_bct_entity: "Part",
        wspf_10_bct_default: "0",
    },
    {
        id: "3",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "ABT-P-94x31",
        wspf_10_bct_entity: "Part",
        wspf_10_bct_default: "0",
    },
    {
        id: "4",
        wspf_10_bct_group: "AC-ER",
        wspf_10_bct_template: "AC-Card",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "5",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "AC-Card",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "6",
        wspf_10_bct_group: "AC-ER+",
        wspf_10_bct_template: "AC-Card",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "7",
        wspf_10_bct_group: "AC-GUEST",
        wspf_10_bct_template: "AC-Card",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "8",
        wspf_10_bct_group: "AC+",
        wspf_10_bct_template: "AC-Card",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "9",
        wspf_10_bct_group: "AC-CGS",
        wspf_10_bct_template: "AC-Card",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "10",
        wspf_10_bct_group: "AC-ES",
        wspf_10_bct_template: "AC-Card",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "11",
        wspf_10_bct_group: "AC",
        wspf_10_bct_template: "AC-Card",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "12",
        wspf_10_bct_group: "AC-ES",
        wspf_10_bct_template: "AC-Dosimeter",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "13",
        wspf_10_bct_group: "AC-ER",
        wspf_10_bct_template: "AC-Dosimeter",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "14",
        wspf_10_bct_group: "AC",
        wspf_10_bct_template: "AC-Dosimeter",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "15",
        wspf_10_bct_group: "AC-GUEST",
        wspf_10_bct_template: "AC-Dosimeter",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "16",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "AC-Dosimeter",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "17",
        wspf_10_bct_group: "AC-CGS",
        wspf_10_bct_template: "AC-Dosimeter",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "18",
        wspf_10_bct_group: "AC+",
        wspf_10_bct_template: "AC-Dosimeter",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "19",
        wspf_10_bct_group: "AC-ER+",
        wspf_10_bct_template: "AC-Dosimeter",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "20",
        wspf_10_bct_group: "ICS-ADM",
        wspf_10_bct_template: "B30-242-595-BLNI",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "21",
        wspf_10_bct_group: "AS+",
        wspf_10_bct_template: "B30-242-595-BLNI",
        wspf_10_bct_entity: "Part",
        wspf_10_bct_default: "-1",
    },
    {
        id: "22",
        wspf_10_bct_group: "AS+",
        wspf_10_bct_template: "B30-242-595-BLNI",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "23",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "B30-242-595-BLNI",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "24",
        wspf_10_bct_group: "ICS-ADM",
        wspf_10_bct_template: "B30-242-595-BLNI",
        wspf_10_bct_entity: "Part",
        wspf_10_bct_default: "-1",
    },
    {
        id: "25",
        wspf_10_bct_group: "MO-IC+",
        wspf_10_bct_template: "B30-242-595-BLNI",
        wspf_10_bct_entity: "Part",
        wspf_10_bct_default: "-1",
    },
    {
        id: "26",
        wspf_10_bct_group: "MO-IC+",
        wspf_10_bct_template: "B30-242-595-BLNI",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "27",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "B30-242-595-BLNI",
        wspf_10_bct_entity: "Part",
        wspf_10_bct_default: "0",
    },
    {
        id: "28",
        wspf_10_bct_group: "BI-ADM",
        wspf_10_bct_template: "B33-149-483-C",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "29",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "B33-149-483-C",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "30",
        wspf_10_bct_group: "MO-IC+",
        wspf_10_bct_template: "B33-149-483-C",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "31",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "B33-37-7425",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "32",
        wspf_10_bct_group: "MO-IC+",
        wspf_10_bct_template: "B33-37-7425",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "33",
        wspf_10_bct_group: "BI-ADM",
        wspf_10_bct_template: "B33-37-7425",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "34",
        wspf_10_bct_group: "XAG-TL",
        wspf_10_bct_template: "Cable75-LabelB-EQ",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "35",
        wspf_10_bct_group: "XA-ADM",
        wspf_10_bct_template: "Cable75-LabelB-EQ",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "36",
        wspf_10_bct_group: "XAG-OP",
        wspf_10_bct_template: "Cable75-LabelB-EQ",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "37",
        wspf_10_bct_group: "XAG-OP",
        wspf_10_bct_template: "Cable75-LabelB-EQ-QR",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "38",
        wspf_10_bct_group: "XAG-TL",
        wspf_10_bct_template: "Cable75-LabelB-EQ-QR",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "39",
        wspf_10_bct_group: "CV-COM",
        wspf_10_bct_template: "EN-CV-big",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "40",
        wspf_10_bct_group: "CV-CO",
        wspf_10_bct_template: "EN-CV-big",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "41",
        wspf_10_bct_group: "CV-ADM",
        wspf_10_bct_template: "EN-CV-big",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "42",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "EN-CV-big",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "43",
        wspf_10_bct_group: "CV-COM",
        wspf_10_bct_template: "EN-CV-big-2",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "44",
        wspf_10_bct_group: "CV-CIN",
        wspf_10_bct_template: "EN-CV-small",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "45",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "EN-CV-small-57",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "46",
        wspf_10_bct_group: "CV-ADM",
        wspf_10_bct_template: "EN-CV-small-57",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "47",
        wspf_10_bct_group: "CV-COM",
        wspf_10_bct_template: "EN-CV-small-57",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "48",
        wspf_10_bct_group: "CV-CO",
        wspf_10_bct_template: "EN-CV-small-57",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "49",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "FB-EQ",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "0",
    },
    {
        id: "50",
        wspf_10_bct_group: "SFBADM",
        wspf_10_bct_template: "FB-EQ",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
    {
        id: "51",
        wspf_10_bct_group: "R5",
        wspf_10_bct_template: "LABELA",
        wspf_10_bct_entity: "Equipment",
        wspf_10_bct_default: "-1",
    },
];

export default {
    gridCode: "84",
    gridName: "OSOBJA",
    dataSpyId: "147368",
    moreRowsPresent: "TRUE",
    cursorPosition: 50,
    records: "3000+",
    gridDataspy: [
        {
            code: "85",
            label: "All Assets",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "136607",
            label: "All Assets w. Contact Persons",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "138760",
            label: "All Assets with Alias",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "147368",
            label: "All_Assets",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "146282",
            label: "All_asset_RP_IL",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "146422",
            label: "Asset Inventory",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "134320",
            label: "Assets for Kiosk",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "139565",
            label: "BI-ADM Assets Sec.",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "139496",
            label: "BI-ADM Assets Security",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "130213",
            label: "BI-ADM Assets Security Filter",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "133266",
            label: "CI-Asset",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "105739",
            label: "CRYO_Asset_All",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "105677",
            label: "CV_ASSETS_All",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "110653",
            label: "FM_Asset_All",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "110116",
            label: "MF Assets",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "145459",
            label: "My Department Assets",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "140090",
            label: "P-OPE Assets",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "121940",
            label: "SFB",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "120849",
            label: "SFB ASSET",
            defaultDataspy: false,
            autorun: false,
        },
        {
            code: "141815",
            label: "Vacuum Cleaners",
            defaultDataspy: false,
            autorun: false,
        },
    ],
    gridField: [
        {
            id: "247",
            name: "equipmentno",
            label: "Asset",
            width: "168",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 1,
        },
        {
            id: "383",
            name: "equipmentdesc",
            label: "Description",
            width: "473",
            dataType: "MIXVARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 2,
        },
        {
            id: "408",
            name: "assetstatus_display",
            label: "Status",
            width: "143",
            dataType: "MIXVARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 3,
        },
        {
            id: "250",
            name: "location",
            label: "Location",
            width: "93",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 4,
        },
        {
            id: "253",
            name: "department",
            label: "Department",
            width: "105",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 5,
        },
        {
            id: "389",
            name: "class",
            label: "Class",
            width: "71",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 6,
        },
        {
            id: "390",
            name: "category",
            label: "Category",
            width: "106",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 7,
        },
        {
            id: "394",
            name: "commissiondate",
            label: "Commission Date",
            width: "140",
            dataType: "DATE",
            pldCase: null,
            ddSpyId: null,
            order: 8,
        },
        {
            id: "395",
            name: "assignedto",
            label: "Assigned To",
            width: "108",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 9,
        },
        {
            id: "401",
            name: "part",
            label: "Part",
            width: "96",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 10,
        },
        {
            id: "398",
            name: "store",
            label: "Store",
            width: "99",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 11,
        },
        {
            id: "399",
            name: "bin",
            label: "Bin",
            width: "70",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 12,
        },
        {
            id: "400",
            name: "lot",
            label: "Lot",
            width: "56",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 13,
        },
        {
            id: "14229",
            name: "udfnum09",
            label: "Height [cm]",
            width: "103",
            dataType: "DECIMAL",
            pldCase: null,
            ddSpyId: null,
            order: 14,
        },
        {
            id: "14228",
            name: "udfnum08",
            label: "Width [cm]",
            width: "99",
            dataType: "DECIMAL",
            pldCase: null,
            ddSpyId: null,
            order: 15,
        },
        {
            id: "14227",
            name: "udfnum07",
            label: "Length [cm]",
            width: "105",
            dataType: "DECIMAL",
            pldCase: null,
            ddSpyId: null,
            order: 16,
        },
        {
            id: "14230",
            name: "udfnum10",
            label: "Weight [kg]",
            width: "102",
            dataType: "DECIMAL",
            pldCase: null,
            ddSpyId: null,
            order: 17,
        },
        {
            id: "515",
            name: "manufacturer",
            label: "Manufacturer",
            width: "114",
            dataType: "VARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 18,
        },
        {
            id: "387",
            name: "model",
            label: "Model",
            width: "120",
            dataType: "MIXVARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 19,
        },
        {
            id: "386",
            name: "serialnumber",
            label: "Serial Number",
            width: "121",
            dataType: "MIXVARCHAR",
            pldCase: null,
            ddSpyId: null,
            order: 20,
        },
        {
            id: "692",
            name: "production",
            label: "Production",
            width: "100",
            dataType: "CHKBOOLEAN",
            pldCase: null,
            ddSpyId: null,
            order: 21,
        },
        {
            id: "407",
            name: "outofservice",
            label: "Out of Service",
            width: "120",
            dataType: "CHKBOOLEAN",
            pldCase: null,
            ddSpyId: null,
            order: 22,
        },
    ],
    row: [
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "I",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "SPMKMOD001-CR000005",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "Magnet Kicker Module",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Installe et Maintenu",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "30", n: "250", t: "location", order: 4 },
                {
                    value: "M01",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "MKM01", n: "389", t: "class", order: 6 },
                {
                    value: "SPMKMOD001",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "30-JUN-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "SPMKMOD001",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                {
                    value: "72",
                    n: "14229",
                    t: "udfnum09",
                    order: 14,
                },
                {
                    value: "65",
                    n: "14228",
                    t: "udfnum08",
                    order: 15,
                },
                {
                    value: "83",
                    n: "14227",
                    t: "udfnum07",
                    order: 16,
                },
                {
                    value: "150",
                    n: "14230",
                    t: "udfnum10",
                    order: 17,
                },
                {
                    value: "CERN",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "", n: "387", t: "model", order: 19 },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "1",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "I",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "SPMKMOD001-CR000006",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "Magnet Kicker Module",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Installe et Maintenu",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "955", n: "250", t: "location", order: 4 },
                {
                    value: "M01",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "MKM01", n: "389", t: "class", order: 6 },
                {
                    value: "SPMKMOD001",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "30-JUN-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                {
                    value: "122200",
                    n: "395",
                    t: "assignedto",
                    order: 9,
                },
                {
                    value: "SPMKMOD001",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                {
                    value: "72",
                    n: "14229",
                    t: "udfnum09",
                    order: 14,
                },
                {
                    value: "65",
                    n: "14228",
                    t: "udfnum08",
                    order: 15,
                },
                {
                    value: "83",
                    n: "14227",
                    t: "udfnum07",
                    order: 16,
                },
                {
                    value: "150",
                    n: "14230",
                    t: "udfnum10",
                    order: 17,
                },
                {
                    value: "CERN",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "", n: "387", t: "model", order: 19 },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "2",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "IMFT",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCVVGSF001-VT000002",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value:
                        "Vanne tiroir RFelectropneumatique DN63 CFF63 LHC Type B VVGSF",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "En fabrication",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "V01",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "VV01", n: "389", t: "class", order: 6 },
                {
                    value: "HCVVGSF001",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "24-AUG-2014",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                {
                    value: "163513",
                    n: "395",
                    t: "assignedto",
                    order: 9,
                },
                {
                    value: "HCVVGSF001",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "VVAG",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "47236-CE74-ACU1",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "3",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "I",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCPIGPB-00004205",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "Pedestrian Location BEACON",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Installe et Maintenu",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                {
                    value: "UP25",
                    n: "250",
                    t: "location",
                    order: 4,
                },
                {
                    value: "CNE01",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CCST01", n: "389", t: "class", order: 6 },
                {
                    value: "CCS-TETRA-B",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "08-APR-2014",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCPIGPB",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "v10.5", n: "387", t: "model", order: 19 },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "4",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CRPSV-16026",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "Ballon 300l GIPAG",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "PGS",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "PSV", n: "389", t: "class", order: 6 },
                {
                    value: "PSV02",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "26-AUG-2015",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                {
                    value: "122200",
                    n: "395",
                    t: "assignedto",
                    order: 9,
                },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "NUGI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "G14", n: "387", t: "model", order: 19 },
                {
                    value: "015185271",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "5",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "I",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                {
                    value: "1,211,884",
                    n: "2419",
                    t: "gisobjid",
                    order: -1,
                },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCPIGPB-00004209",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "Pedestrian Location BEACON ",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Installe et Maintenu",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                {
                    value: "RA27",
                    n: "250",
                    t: "location",
                    order: 4,
                },
                {
                    value: "CNE01",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CCST01", n: "389", t: "class", order: 6 },
                {
                    value: "CCS-TETRA-B",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "08-APR-2014",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCPIGPB",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "v10.5", n: "387", t: "model", order: 19 },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "6",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "I",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                {
                    value: "1,212,361",
                    n: "2419",
                    t: "gisobjid",
                    order: -1,
                },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCPIGPB-00004210",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "Pedestrian Location BEACON ",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Installe et Maintenu",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                {
                    value: "RA27",
                    n: "250",
                    t: "location",
                    order: 4,
                },
                {
                    value: "CNE01",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CCST01", n: "389", t: "class", order: 6 },
                {
                    value: "CCS-TETRA-B",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "08-APR-2014",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                {
                    value: "122200",
                    n: "395",
                    t: "assignedto",
                    order: 9,
                },
                {
                    value: "HCPIGPB",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "v10.5", n: "387", t: "model", order: 19 },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "7",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "IW",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "QVPT-PFE031",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "Pompe turbomoléculaire",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Dechets",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "184", n: "250", t: "location", order: 4 },
                {
                    value: "RWC",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "QAVP", n: "389", t: "class", order: 6 },
                {
                    value: "QKAVPT",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "16-DEC-2015",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "Q07252", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                {
                    value: "1",
                    n: "14229",
                    t: "udfnum09",
                    order: 14,
                },
                {
                    value: "3",
                    n: "14228",
                    t: "udfnum08",
                    order: 15,
                },
                {
                    value: "2",
                    n: "14227",
                    t: "udfnum07",
                    order: 16,
                },
                {
                    value: "20",
                    n: "14230",
                    t: "udfnum10",
                    order: 17,
                },
                {
                    value: "PFEIF",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "TMH071",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "13290078",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "8",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CRRP-03658",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "VASE EXPANSION RESEAU EAU GLACÉE CTA",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "PGS",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "PRES", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "29-JUN-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                {
                    value: "122200",
                    n: "395",
                    t: "assignedto",
                    order: 9,
                },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "PNEU",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "Statico 300-3",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "2013-606393",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "9",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "IST",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CRPSV-16469",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "RESEAU CHAUFFAGE CIRCUIT SECONDAIRE",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Stocké",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "15", n: "250", t: "location", order: 4 },
                {
                    value: "FFM2",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "PSV", n: "389", t: "class", order: 6 },
                {
                    value: "PSV02",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "30-JUN-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                {
                    value: "122200",
                    n: "395",
                    t: "assignedto",
                    order: 9,
                },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "NUGI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "G25", n: "387", t: "model", order: 19 },
                {
                    value: "11191484",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "10",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "I",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCSFEXTP1C-DU160001",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "CO2 5Kg",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Installe et Maintenu",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "595", n: "250", t: "location", order: 4 },
                {
                    value: "SFB",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "SFEXT", n: "389", t: "class", order: 6 },
                {
                    value: "SFP1C",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "05-DEC-2015",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                {
                    value: "139480",
                    n: "395",
                    t: "assignedto",
                    order: 9,
                },
                {
                    value: "HCSFEXTP1C",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                {
                    value: "90",
                    n: "14229",
                    t: "udfnum09",
                    order: 14,
                },
                {
                    value: "20",
                    n: "14228",
                    t: "udfnum08",
                    order: 15,
                },
                {
                    value: "20",
                    n: "14227",
                    t: "udfnum07",
                    order: 16,
                },
                {
                    value: "12",
                    n: "14230",
                    t: "udfnum10",
                    order: 17,
                },
                {
                    value: "",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "", n: "387", t: "model", order: 19 },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "11",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "I",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "I",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCSFEXTP1C-DU160002",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "CO2 5Kg",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Installe et Maintenu",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "SX5", n: "250", t: "location", order: 4 },
                {
                    value: "SFB",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "SFEXT", n: "389", t: "class", order: 6 },
                {
                    value: "SFP1C",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "05-DEC-2015",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                {
                    value: "50123",
                    n: "395",
                    t: "assignedto",
                    order: 9,
                },
                {
                    value: "HCSFEXTP1C",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                {
                    value: "90",
                    n: "14229",
                    t: "udfnum09",
                    order: 14,
                },
                {
                    value: "20",
                    n: "14228",
                    t: "udfnum08",
                    order: 15,
                },
                {
                    value: "20",
                    n: "14227",
                    t: "udfnum07",
                    order: 16,
                },
                {
                    value: "12",
                    n: "14230",
                    t: "udfnum10",
                    order: 17,
                },
                {
                    value: "",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "", n: "387", t: "model", order: 19 },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "12",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00135",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX NASHUA PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "27-SEP-1995",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "NASH",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "P391", n: "387", t: "model", order: 19 },
                {
                    value: "5251534",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "13",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000418",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "14",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000420",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "15",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSRKT017-00000410",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 JBOD storage enclosure rails kit",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSRKT", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSRKT017",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "XYR",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "0984351-03",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "16",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000389",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "17",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000424",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "18",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00239",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX RICOH PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "15-OCT-1997",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "RICO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "L2700", n: "387", t: "model", order: 19 },
                {
                    value: "3370300043",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "19",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00240",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX NASHUA PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "11-NOV-1997",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "NASH",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "P397", n: "387", t: "model", order: 19 },
                {
                    value: "7043768",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "20",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00241",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "27-NOV-1997",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B100", n: "387", t: "model", order: 19 },
                {
                    value: "EXU 08379",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "21",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00242",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "27-NOV-1997",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "L800", n: "387", t: "model", order: 19 },
                {
                    value: "EFW 04929",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "22",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00243",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "08-DEC-1997",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "L800", n: "387", t: "model", order: 19 },
                {
                    value: "EFW 04845",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "23",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000390",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "24",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000396",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "25",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00112",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX NASHUA PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "28-APR-1995",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "NASH",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "P391", n: "387", t: "model", order: 19 },
                {
                    value: "4661454",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "26",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00114",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX NASHUA PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "03-MAY-1995",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "NASH",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "P391", n: "387", t: "model", order: 19 },
                {
                    value: "4722642",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "27",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00115",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX NASHUA PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "04-MAY-1995",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "NASH",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "P391", n: "387", t: "model", order: 19 },
                {
                    value: "4722716",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "28",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00118",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX NASHUA PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "16-MAY-1995",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "NASH",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "P491", n: "387", t: "model", order: 19 },
                {
                    value: "4040686",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "29",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSRKT017-00000406",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 JBOD storage enclosure rails kit",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSRKT", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSRKT017",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "XYR",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "0984351-03",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "30",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000405",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "31",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000398",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "32",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00119",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX NASHUA PAPIER THERMIQUE",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "17-MAY-1995",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "NASH",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "F190", n: "387", t: "model", order: 19 },
                {
                    value: "F2246210002023*1AU",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "33",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00345",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "08-DEC-1999",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "L800", n: "387", t: "model", order: 19 },
                {
                    value: "EFW 60388",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "34",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00346",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "13-DEC-1999",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B150", n: "387", t: "model", order: 19 },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "35",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00347",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "17-DEC-1999",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "L800", n: "387", t: "model", order: 19 },
                {
                    value: "EFW 60376",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "36",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00348",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "05-JAN-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B150", n: "387", t: "model", order: 19 },
                {
                    value: "EWZ 245300",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "37",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00349",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "05-JAN-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B150", n: "387", t: "model", order: 19 },
                {
                    value: "EZW 245364",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "38",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00350",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "05-JAN-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B150", n: "387", t: "model", order: 19 },
                {
                    value: "EZW 245297",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "39",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00351",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "13-DEC-1999",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "L800", n: "387", t: "model", order: 19 },
                {
                    value: "EFW 60364",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "40",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00352",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "27-JAN-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B150", n: "387", t: "model", order: 19 },
                {
                    value: "EWZ 203663",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "41",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00353",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "27-JAN-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "L800", n: "387", t: "model", order: 19 },
                {
                    value: "EFW 60371",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "42",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00355",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "09-FEB-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B150", n: "387", t: "model", order: 19 },
                {
                    value: "EWZ 218145",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "43",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00357",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "01-MAR-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "L800", n: "387", t: "model", order: 19 },
                {
                    value: "EFW 61516",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "44",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00358",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "21-MAR-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B150", n: "387", t: "model", order: 19 },
                {
                    value: "EWZ 218129",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "45",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00359",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "30-MAR-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B155", n: "387", t: "model", order: 19 },
                {
                    value: "EKV 04135",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "46",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00360",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX CANON PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "11-APR-2000",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "CANO",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "B150", n: "387", t: "model", order: 19 },
                {
                    value: "EWZ 245298",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "47",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "CFXM-00013",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "FAX LANIER PAPIER A4",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "CTEL",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CT", n: "389", t: "class", order: 6 },
                {
                    value: "CTFX",
                    n: "390",
                    t: "category",
                    order: 7,
                },
                {
                    value: "19-APR-1993",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                { value: "", n: "401", t: "part", order: 10 },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LANI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                { value: "3400", n: "387", t: "model", order: 19 },
                {
                    value: "127 435",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "true",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "true",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "48",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000395",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "49",
        },
        {
            cell: [
                {
                    value: "*",
                    n: "248",
                    t: "organization",
                    order: -1,
                },
                {
                    value: "D",
                    n: "384",
                    t: "assetstatus",
                    order: -1,
                },
                { value: "A", n: "409", t: "eqtype", order: -1 },
                { value: "", n: "2419", t: "gisobjid", order: -1 },
                {
                    value: "D",
                    n: "2776",
                    t: "statusrcode",
                    order: -1,
                },
                {
                    value: "HCCSCAB002-00000400",
                    n: "247",
                    t: "equipmentno",
                    order: 1,
                },
                {
                    value: "E4 1m SAS cable mini-SFF 8088",
                    n: "383",
                    t: "equipmentdesc",
                    order: 2,
                },
                {
                    value: "Hors service definitif",
                    n: "408",
                    t: "assetstatus_display",
                    order: 3,
                },
                { value: "", n: "250", t: "location", order: 4 },
                {
                    value: "*INA",
                    n: "253",
                    t: "department",
                    order: 5,
                },
                { value: "CSCAB", n: "389", t: "class", order: 6 },
                { value: "", n: "390", t: "category", order: 7 },
                {
                    value: "03-MAY-2016",
                    n: "394",
                    t: "commissiondate",
                    order: 8,
                },
                { value: "", n: "395", t: "assignedto", order: 9 },
                {
                    value: "HCCSCAB002",
                    n: "401",
                    t: "part",
                    order: 10,
                },
                { value: "", n: "398", t: "store", order: 11 },
                { value: "", n: "399", t: "bin", order: 12 },
                { value: "*", n: "400", t: "lot", order: 13 },
                { value: "", n: "14229", t: "udfnum09", order: 14 },
                { value: "", n: "14228", t: "udfnum08", order: 15 },
                { value: "", n: "14227", t: "udfnum07", order: 16 },
                { value: "", n: "14230", t: "udfnum10", order: 17 },
                {
                    value: "LSI",
                    n: "515",
                    t: "manufacturer",
                    order: 18,
                },
                {
                    value: "LS-150-3087-00",
                    n: "387",
                    t: "model",
                    order: 19,
                },
                {
                    value: "",
                    n: "386",
                    t: "serialnumber",
                    order: 20,
                },
                {
                    value: "false",
                    n: "692",
                    t: "production",
                    order: 21,
                },
                {
                    value: "false",
                    n: "407",
                    t: "outofservice",
                    order: 22,
                },
            ],
            id: "50",
        },
    ],
};
