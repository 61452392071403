import { backendClient as client } from "api/clients";

const RESOURCE_PATH = "/printers";

const getMany = async ({ config = {} } = {}) => {
    const response = await client.get(`${RESOURCE_PATH}`, config);
    return response.body.data;
}

export default { getMany };
