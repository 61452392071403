import axios from 'axios'

export const cancellableRequest = asyncFn => ({ config, ...rest }) => {
    const source = axios.CancelToken.source()

    const promise = asyncFn({
        ...rest,
        config: {
            ...config,
            cancelToken: source.token,
        }
    });
 
    // Cancel the request if React Query calls the `promise.cancel` method
    promise.cancel = () => {
        source.cancel('Query was cancelled by React Query')
    }
 
   return promise;
}
