import React, { useContext } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import LayoutSelectorRoute from "routes/LayoutSelectorRoute";
import WelcomePage from "pages/WelcomePage";
import NotFoundPage from "pages/NotFoundPage";
import { ROUTES } from "routes/Routes";
import PrintPage from "pages/PrintPage";
import { UserDataContext } from "contexts/UserDataContext";
import LoadingPage from "pages/LoadingPage";
import ErrorPage from "pages/ErrorPage";
import { AppSettingsContext } from "contexts/AppSettingsContext";
import { ThemeProvider } from "@material-ui/core";
import Themes from 'eam-components/dist/ui/components/theme';
import config from './config';


const App = () => {
    const { isFetching: isFetchingUserData, userDataError } = useContext(UserDataContext);
    const { isFetching: isFetchingAppSettings, appSettingsError, appSettings } = useContext(AppSettingsContext);
    const isInitialLoading = [isFetchingUserData, isFetchingAppSettings].some(Boolean);
    const errors = [userDataError, appSettingsError];

    const selectedTheme = Themes[config.theme[appSettings?.environment]] || Themes.DANGER;
    
    return (
        <ThemeProvider theme={selectedTheme}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                {isInitialLoading ? (
                    <LoadingPage />
                ) : errors.some(Boolean) ? (
                    <ErrorPage errors={errors} />
                ) : (
                    <Switch>
                        <LayoutSelectorRoute exact path={ROUTES.homepage} component={WelcomePage} />
                        <LayoutSelectorRoute path={Object.values(ROUTES.print)} component={PrintPage} />
                        <LayoutSelectorRoute component={NotFoundPage} />
                    </Switch>
                )}
            </BrowserRouter>
        </ThemeProvider>
    );
};

export default App;
