import React from 'react'
import { makeStyles, Typography, CircularProgress, Box } from "@material-ui/core"

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    text: {
        textAlign: "center"
    }
})

const LoadingPage = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress  />
            <Box m={2}>
                <Typography variant="subtitle2" className={classes.text}>Loading EAM Barcoding</Typography>
            </Box>
        </div>
    )
}

export default LoadingPage
