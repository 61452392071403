import React, { createContext } from "react";
import AppAPI from "api/app";
import { useQuery } from "react-query";

export const AppSettingsContext = createContext({});

export const AppSettingsProvider = (props) => {
    const { isLoading, error, data, isError } = useQuery(AppAPI.GET_SETTINGS, AppAPI.getSettings);    

    const appSettingsContext = {
        appSettings: data?.body?.data,
        isFetching: isLoading,
        appSettingsError: error,
        isAppSettingsError: isError,
    };

    return (
        <AppSettingsContext.Provider value={appSettingsContext}>
            {props.children}
        </AppSettingsContext.Provider>
    );
};

export const { Consumer } = AppSettingsContext;

export const APP_SETTINGS_KEYS = {
    BARCODING_SOFTWARE: "barcodingSoftware",
    PRINTING_LIMIT: "printingLimit",
    MAX_PRINT_COPIES: "maxPrintCopies",
}