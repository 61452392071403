import React from "react";
import Footer from "components/Footer";
import Nav from "components/Nav";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        flex: "1 0 auto",
        display: "flex",
        minHeight: "100vh",
        width: "100%",
        height: "100%",
        flexDirection: "column",
    },
    nav: {},
    body: {
        flex: "1",
        height: "100%",
        overflowY: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    footer: {},
});

const ClassicLayout = ({ children }) => {
    const classes = useStyles();
    return (
        <section className={classes.root}>
            <section className={classes.nav}>
                <Nav />
            </section>
            <section className={classes.body}>{children}</section>
            <section className={classes.footer}>
                <Footer />
            </section>
        </section>
    );
};

export default ClassicLayout;
