import React, { useState, useContext, useEffect, useMemo } from "react";
import axios from 'axios'
import {
    Container,
    Box,
    Button,
    makeStyles,
    TextField,
    Paper,
    Grid,
    CircularProgress,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import AMMGrid from "components/AMMGrid/AMMGrid";
import {
    getRowAsAnObject,
} from "mockData";
import PrintIcon from "@material-ui/icons/Print";
import FilterIcon from "@material-ui/icons/FilterList";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { EAMFilterField, EAMCellField } from "components/AMMGrid/tools/eam";
import TemplatesAPI from "api/templates";
import PrintersAPI from "api/printers";
import GridsAPI from "api/grids";
import BarcodingAPI from "api/barcoding";
import { AppSettingsContext, APP_SETTINGS_KEYS } from "contexts/AppSettingsContext";
import { GRID_NAME_ROUTE_MAP, TEMPLATE_ENTITY, GRID_CODE_KEY } from "constants/general";
import { useQuery, queryCache, usePaginatedQuery } from "react-query";
import { cancellableRequest } from "api/helpers";
import { toast } from "react-toastify";
import queryString from "query-string";
import AMMGridKeyboardHandler from "components/AMMGrid/AMMGridKeyboardHandler";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    page: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    panelSection: {
        display: "flex",
        flexDirection: "column",
    },
    actionsSection: {
        display: "flex",
        justifyContent: "center",
    },
    previewSection: {
        display: "flex",
        justifyContent: "center",
    },
    previewImage: {
        maxWidth: 250,
        border: '1px dashed #c4c4c4',
        borderRadius: '4px',
        padding: theme.spacing(1),
    }
}));

const customRowProps = (row) => ({
    style: {
        cursor: 'pointer',
        backgroundColor: row.isSelected ? 'aliceblue' : 'white',
        '&:hover': {
            backgroundColor: 'grey',
        },
    },
    onClick: () => row.toggleRowSelected()
});

const PrintPage = () => {
    const location = useLocation();
    const { gridId, gridName, pageSize, ...filterQueryParams} = queryString.parse(location.search);
    const initialFilters = useMemo(() => Object.entries(filterQueryParams).filter(([key]) => key.startsWith('filter:')).map(([key, value]) => {
        const valueParams = value.split('|||');
        const filterKey = key.replace('filter:', '');
        return {
            id: filterKey,
            value: {
                fieldName: filterKey,
                fieldValue: valueParams[0],
                joiner: 'AND',
                operator: valueParams[1] ?? '='
            }
        }
    }), [filterQueryParams]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [dataspies, setDataspies] = useState([]);
    const [loadingMetadata, setLoadingMetadata] = useState(false);
    const [selectedPrinter, setSelectedPrinter] = useState(undefined);
    const [selectedTemplate, setSelectedTemplate] = useState(undefined);
    const [selectedDatapsy, setSelectedDataspy] = useState(undefined);
    const [printQuantity, setPrintQuantity] = useState(1);
    const [disableFilters, setDisableFilters] = useState(false);
    const [filters, setFilters] = useState(initialFilters);
    const [sortBy, setSortBy] = useState([]);
    const [data, setData] = useState({});
    const { appSettings } = useContext(AppSettingsContext);
    const [pageIndex, setPageIndex] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(Math.min(pageSize ?? 50, appSettings.printingLimit));
    const [manualQuery, setManualQuery] = useState(0);
    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const [isSendingPrintJob, setSendingPrintJob] = useState(false);
    const [isErrorLoadingPreview, setErrorLoadingPreview] = useState(false);
    const classes = useStyles();

    const currentGridName = GRID_NAME_ROUTE_MAP[location.pathname] || gridName;
    const currentGridID = gridId;
    const currentTemplateEntity = TEMPLATE_ENTITY[currentGridName];

    const { data: templates = [], isLoading: isLoadingTemplates } = useQuery(`templates`, cancellableRequest(TemplatesAPI.getMany), { refetchOnWindowFocus: false });
    const { data: printers = [], isLoading: isLoadingPrinters } = useQuery('printers', cancellableRequest(PrintersAPI.getMany), { refetchOnWindowFocus: false });

    useEffect(() => {
        if (pageSize && pageSize > appSettings.printingLimit) {
            toast.warning(`Page size (${pageSize}) is greater than maximum print limit (${appSettings.printingLimit}). Defaulting to ${appSettings.printingLimit}.`, {
                autoClose: false
            });
        }
    }, [appSettings.printingLimit, pageSize, rowsPerPage])

    const computedTemplates = useMemo(() =>
        templates
            .filter(template => !currentTemplateEntity || template.entity === currentTemplateEntity)
            .sort((a, b) => -b.entity.localeCompare(a.entity))
    , [currentTemplateEntity, templates]);

    useEffect(() => {
        if (currentTemplateEntity) {
            const defaultTemplate = computedTemplates.find(template => template.userGroupDefault);
            setSelectedTemplate(defaultTemplate);
        }
    }, [currentTemplateEntity, computedTemplates])

    useEffect(() => {
        setSelectedPrinter(printers.find(printer => printer.userGroupDefault));
    }, [printers])

    useEffect(() => {
        setErrorLoadingPreview(false);
    }, [selectedTemplate])

    const handleQuantityChange = (event) => {
        const newQuantity = Number(event.target.value)
        !!newQuantity
            ? setPrintQuantity(Math.min(Math.max(1, Number(event.target.value)), appSettings[APP_SETTINGS_KEYS.MAX_PRINT_COPIES]))
            : setPrintQuantity('');
        event.stopPropagation();
    };

    const handleTableStateChange = React.useCallback(
        async ({ sortBy, filters, selectedRows }) => {
            setSelectedRows(selectedRows);
            setFilters(filters ?? initialFilters);
            setSortBy(sortBy);
        }
    , [initialFilters]);

    const fetchData = React.useCallback(({ pageIndex, rowsPerPage, sortBy, filters, selectedDatapsy }) => {
        const baseGridRequest = {
            ...currentGridName && { gridName: currentGridName, userFunctionName: currentGridName },
            ...currentGridID && { gridId },
            countTotal: true,
            useNative: true,
        }
        const source = axios.CancelToken.source()
        const isLoadingNewDataspy = !selectedDatapsy?.code || selectedDatapsy?.code !== data.dataSpyId;
        setLoadingMetadata(true);
        const promise = GridsAPI.getData({
            gridRequest: {
                ...baseGridRequest,
                dataspyID: selectedDatapsy?.code,
                cursorPosition: (pageIndex * rowsPerPage) + 1,
                includeMetadata: true,
                countTotal: true,
                rowCount: rowsPerPage,
                gridFilter: (isLoadingNewDataspy ? initialFilters : filters).map(f => {
                    const filter = f.value;
                    const allowedFilter = Object.keys(filter)
                    .filter(key => ['fieldName', 'fieldValue', 'joiner', 'operator'].includes(key))
                    .reduce((newFilterObj, key) => ({
                        ...newFilterObj,
                        [key]: filter[key]
                    }), {});
                    return allowedFilter
                }).filter(filter => filter.fieldValue !== undefined || ['IS EMPTY', 'NOT EMPTY'].includes(filter.operator)),
                ...!isLoadingNewDataspy && { gridSort: sortBy.map(sort => ({ sortBy: sort.id, sortType: sort.desc === true ? "DESC" : "ASC" })) },
            },
            config: {
                cancelToken: source.token,
            }
        }).then(response => {
            const gridResult = response.body.data;
            setDataspies(gridResult.gridDataspy);
            const dataspy = gridResult.gridDataspy.find(ds => ds.code === gridResult.dataSpyId);
            setSelectedDataspy(dataspy);
            setData(gridResult);
            setLoadingMetadata(false);
            return {
                ...gridResult,
                gridField: data.gridField ? data.gridField : gridResult.gridField,
            }
        }).finally(() => {
            setLoadingMetadata(false);
        });
        promise.cancel = () => {
            source.cancel('Query was cancelled by React Query')
        }
        return promise;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentGridID, currentGridName, data.dataSpyId, data.gridField, gridId, gridName, location.pathname]);

    const {
        resolvedData,
        latestData,
    } = usePaginatedQuery([`${GridsAPI.GET_DATA}/${location.pathname}`, pageIndex, rowsPerPage, sortBy, manualQuery], () => fetchData({ pageIndex, rowsPerPage, sortBy, filters, selectedDatapsy }), { refetchOnWindowFocus: false });
    const totalRecords = +(resolvedData?.records ?? '').replace('+', '');
    const hasUnkownTotalRecords = (resolvedData?.records ?? '').includes('+');
    const isLoadingFreshData = !latestData;

    const columns = useMemo(
        () =>
            (data?.gridField || []).sort((a, b) => a.order - b.order).map((field) => ({
                id: field.name,
                Header: field.label,
                accessor: field.name,
                width: Number(field.width),
                minWidth: 0,
                maxWidth: 99999,
                dataType: field.dataType,
                Filter: EAMFilterField,
                Cell: EAMCellField,
            }))
    , [data]);

    const computedData = useMemo(() => (resolvedData?.row || []).map((row) => getRowAsAnObject(row)), [resolvedData]);

    const handleOnSearch = React.useCallback(() => {
        setPageIndex(0);
        setManualQuery(manualQuery + 1);
        queryCache.invalidateQueries(`${GridsAPI.GET_DATA}/${location.pathname}`)
    }, [location.pathname, manualQuery]);

    const handlePrint = React.useCallback(async () => BarcodingAPI.print({
        printRequest: {
            printer: selectedPrinter?.id,
            template: selectedTemplate?.id,
            quantity: printQuantity,
            codeKey: GRID_CODE_KEY[currentGridName],
            selectedItems: selectedRows,
        }
    }), [currentGridName, printQuantity, selectedPrinter, selectedRows, selectedTemplate]);

    const getRowId = React.useCallback((row, relativeIndex) => `${pageIndex}###${relativeIndex}`, [pageIndex])

    const handleConfirmationClose = () => {
        setConfirmationOpen(false);
    }

    const handleConfirmationConfirm = () => {
        setSendingPrintJob(true)
        handlePrint().then(() => {
            handleConfirmationClose();
            toast.success('Print Job sent successfully')
        }).catch(() => {
            handleConfirmationClose();
            toast.error('Print Job failed. Try again or contact support.')
        }).finally(() => {
            setSendingPrintJob(false)
        });
    }

    const toggleFilters = React.useCallback(() => setDisableFilters(!disableFilters), [disableFilters])

    return (
        <Container disableGutters maxWidth={false} style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
         }}>
            <Box component="section" m={2} className={classes.page}>
                <Paper style={{
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    {appSettings.printJobMode !== 'PRINT' && <Alert severity="warning">Print Mode is disabled. Debug print mode is active.</Alert>}
                    <Box m={2} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '400px',
                        flex: '1 0 0',
                    }}>
                        <AMMGrid
                            columns={columns}
                            data={computedData}
                            getRowId={getRowId}
                            onTableStateChange={handleTableStateChange}
                            disableFilters={disableFilters}
                            initialState={{ pageIndex, filters }}
                            selectable
                            manualFilters
                            manualSortBy
                            disableMultiSort
                            autoResetSortBy={false}
                            autoResetFilters={false}
                            autoResetSelectedRows={false}
                            getRowProps={customRowProps}
                            loading={isLoadingFreshData}>
                            {React.useCallback(({ tableRender, tableInstance }) => (
                                <>
                                    <AMMGridKeyboardHandler tableInstance={tableInstance} search={handleOnSearch} toggleFilters={toggleFilters} />
                                    <Grid container direction="row" justify="space-between" style={{
                                        padding: '8px',
                                        background: '#fafafa',
                                        border: '1px solid #eeeeee',
                                        borderBottom: 'none',
                                        borderRadius: '4px 4px 0px 0px',
                                    }}>
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Autocomplete
                                                value={selectedDatapsy || {}}
                                                autoHighlight
                                                fullWidth
                                                loading={loadingMetadata}
                                                disableClearable={!selectedDatapsy}
                                                options={[selectedDatapsy || {}, ...dataspies].filter(Boolean)}
                                                filterSelectedOptions
                                                getOptionSelected={(option, value) => value.code === option.code}
                                                getOptionLabel={(dataspy) => dataspy.label || ''}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        InputLabelProps={{ shrink: true }}
                                                        label="Dataspy"
                                                        margin="dense"
                                                        variant="outlined"
                                                        size="small"
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            startAdornment: (
                                                            <React.Fragment>
                                                                {loadingMetadata ? <CircularProgress style={{ marginLeft: 4 }} size={20} /> : null}
                                                                {params.InputProps.startAdornment}
                                                            </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                                onChange={(e, newDataspy) => {
                                                    setSelectedDataspy(newDataspy);
                                                    tableInstance.setAllFilters(initialFilters);
                                                    tableInstance.setSortBy([]);
                                                    tableInstance.toggleAllRowsSelected(false);
                                                    handleOnSearch();
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            />
                                        </Grid>
                                        <Grid container item xs={12} sm md
                                                spacing={1}
                                                direction="row"
                                                justify="flex-end"
                                                alignItems="center">
                                            <Grid item>
                                                <Button variant="outlined" size="small" onClick={toggleFilters} startIcon={<FilterIcon/>}>{disableFilters ? 'Show Filters': 'Hide Filters'}</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="outlined" size="small" onClick={() => tableInstance.setAllFilters(initialFilters)}>Reset Filters</Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="outlined" size="small" color="primary" startIcon={<SearchIcon/>} onClick={() => {
                                                    tableInstance.toggleAllRowsSelected(false);
                                                    handleOnSearch();
                                                }}>Search</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {tableRender}
                                    <Box style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        background: '#fafafa',
                                        border: '1px solid #eeeeee',
                                        borderTop: 'none',
                                        borderRadius: '0 0 4px 4px',
                                    }}>
                                        <TablePagination
                                            rowsPerPageOptions={[
                                                ...new Set([
                                                    rowsPerPage,
                                                    50,
                                                    100,
                                                    250,
                                                    500,
                                                    1000,
                                                    +totalRecords,
                                                    +appSettings.printingLimit
                                                ])].sort((a, b) => a - b)
                                                .filter(Boolean)
                                                .filter(v => v <= appSettings.printingLimit)
                                            }
                                            component="div"
                                            count={totalRecords}
                                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${hasUnkownTotalRecords ? count + '+' : count}`}
                                            rowsPerPage={rowsPerPage}
                                            page={pageIndex}
                                            onChangePage={(event, newPage) => {
                                                setPageIndex(newPage);
                                                tableInstance.toggleAllRowsSelected(false);
                                                event.stopPropagation();
                                            }}
                                            onChangeRowsPerPage={(event) => {
                                                setRowsPerPage(Number(event.target.value))
                                                setPageIndex(0);
                                                tableInstance.toggleAllRowsSelected(false);
                                                event.stopPropagation();
                                            }}
                                        />
                                        <Box m={1}>
                                            <Button size='small' onClick={() => alert('Export to CSV')}>Export to CSV</Button>
                                        </Box>
                                    </Box>
                                </>
                            ), [appSettings.printingLimit, dataspies, disableFilters, handleOnSearch, hasUnkownTotalRecords, initialFilters, loadingMetadata, pageIndex, rowsPerPage, selectedDatapsy, toggleFilters, totalRecords])}
                        </AMMGrid>
                    </Box>
                    <Box component="section" m={2} className={classes.panelSection}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    value={selectedPrinter || {}}
                                    disableClearable={!selectedPrinter}
                                    options={[...printers].filter(Boolean)}
                                    getOptionSelected={(option, value) => value.id === option.id}
                                    getOptionLabel={(option) => option.id || ''}
                                    autoHighlight
                                    fullWidth
                                    filterSelectedOptions={!selectedPrinter}
                                    loading={isLoadingPrinters}
                                    renderOption={(printer) => (
                                        <React.Fragment>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span style={{ fontWeight: printer.userGroupDefault ? 'bold' : 'normal'  }}>{printer.id}</span>
                                                <span style={{ fontSize: 12, color: '#8c8c8c' }}>{printer.description}</span>
                                            </div>
                                        </React.Fragment>
                                      )}
                                    renderInput={React.useCallback((params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            label="Printer"
                                            margin="dense"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                  <React.Fragment>
                                                    {isLoadingPrinters ? <CircularProgress style={{ marginLeft: 4 }} size={20} /> : null}
                                                    {params.InputProps.startAdornment}
                                                  </React.Fragment>
                                                ),
                                            }}
                                            helperText={selectedPrinter?.description}
                                        />
                                    ), [isLoadingPrinters, selectedPrinter])}
                                    onChange={(e, newValue) => {
                                        setSelectedPrinter(newValue);
                                        e.stopPropagation();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    value={selectedTemplate || {}}
                                    disableClearable={!selectedTemplate}
                                    options={[...computedTemplates].filter(Boolean)}
                                    getOptionSelected={(option, value) => value.id === option.id}
                                    getOptionLabel={(option) => option.id || ''}
                                    autoHighlight
                                    fullWidth
                                    filterSelectedOptions={!selectedTemplate}
                                    loading={isLoadingTemplates}
                                    groupBy={(template) => template.entity}
                                    renderOption={(template) => (
                                        <span style={{ fontWeight: template.userGroupDefault ? 'bold' : 'normal'  }}>{template.id}</span>
                                    )}
                                    renderInput={React.useCallback((params) => (
                                        <TextField
                                            {...params}
                                            InputLabelProps={{ shrink: true }}
                                            label="Template"
                                            margin="dense"
                                            variant="outlined"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                  <React.Fragment>
                                                    {isLoadingTemplates ? <CircularProgress style={{ marginLeft: 4 }} size={20} /> : null}
                                                    {params.InputProps.startAdornment}
                                                  </React.Fragment>
                                                ),
                                            }}
                                            helperText={isErrorLoadingPreview ? 'Preview is not available.' : ''}
                                        />
                                    ), [isErrorLoadingPreview, isLoadingTemplates])}
                                    onChange={(e, newValue) => {
                                        setSelectedTemplate(newValue);
                                        e.stopPropagation();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    label="Quantity"
                                    variant="outlined"
                                    margin="dense"
                                    value={printQuantity}
                                    onChange={handleQuantityChange}
                                    inputProps={{
                                        step: 1,
                                        type: 'number',
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {selectedTemplate?.id &&
                            <Box
                                component="section"
                                m={2}
                                className={classes.previewSection}>
                                    {!isErrorLoadingPreview &&
                                        <a
                                            href={`${appSettings.templatesPreviewLocation}${selectedTemplate.id}.png`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <img
                                                onError={() => setErrorLoadingPreview(true)}
                                                className={classes.previewImage}
                                                src={`${appSettings.templatesPreviewLocation}${selectedTemplate.id}.png`}
                                                alt="preview"
                                            />
                                        </a>
                                    }
                            </Box>
                        }
                        <Box
                            component="section"
                            m={2}
                            className={classes.actionsSection}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                startIcon={<PrintIcon />}
                                endIcon={!!selectedRows.length &&
                                    <span style={{
                                        fontSize: 14,
                                        fontWeight: 300,
                                        textTransform: 'none',
                                    }}>
                                        {`${selectedRows.length} label(s) x ${printQuantity}`}
                                    </span>
                                }
                                disabled={!(selectedRows.length && selectedPrinter && selectedTemplate && printQuantity)}
                                onClick={() => setConfirmationOpen(true)}
                            >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <span>Print</span>

                                </div>
                            </Button>
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <Dialog
                open={isConfirmationOpen}
                keepMounted
                onClose={handleConfirmationClose}
            >
                <DialogTitle>Print Request Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>Press 'Confirm' if you want to send the print request.</DialogContentText>
                    <DialogContentText>This action is <b>irreversible</b>.</DialogContentText>
                    {isSendingPrintJob &&
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <CircularProgress />
                            <Box m={1}>
                                <span>Sending print job...</span>
                            </Box>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmationClose} color="default" disabled={isSendingPrintJob}>
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmationConfirm} color="primary" disabled={isSendingPrintJob}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default PrintPage;
