import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { UserDataProvider } from "contexts/UserDataContext";
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { AppSettingsProvider } from "contexts/AppSettingsContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthWrapper from "AuthWrapper";

ReactDOM.render(
    <React.StrictMode>
        <AuthWrapper>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <AppSettingsProvider>
                        <UserDataProvider>
                            <CssBaseline />
                            <ToastContainer position="bottom-right" />
                            <App />
                        </UserDataProvider>
                    </AppSettingsProvider>
            </MuiPickersUtilsProvider>
        </AuthWrapper>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
