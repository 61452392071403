import axios from "axios";
import { AUTH_MECHANISM, tokens } from "AuthWrapper";
import keycloak from "../keycloak";

const convertResponse = (response) => ({
    status: response.status,
    body: response.data,
    // TODO, REVIEW
});

const convertError = (error) => {
    // TODO
    if (axios.isCancel(error)) {
        throw new axios.Cancel("Operation canceled due to timeout!");
    }
    return Promise.reject(error);
};

const backendClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND,
});

backendClient.interceptors.response.use(convertResponse, convertError);

backendClient.interceptors.request.use(
    (previousConfig) =>
        AUTH_MECHANISM === "OPENID"
            ? keycloak.updateToken(300).then(() => {
                  const nextConfig = previousConfig;
                  if (tokens && tokens.token) {
                      nextConfig.headers.Authorization = `Bearer ${tokens.token}`;
                  }
                  return nextConfig;
              })
            : previousConfig,
    (error) => {
        Promise.reject(error);
    }
);

export { backendClient };
