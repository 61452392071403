import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    footer: {
        backgroundColor: theme.palette.primary.main,
        padding: theme.spacing(),
        color: theme.palette.primary.contrastText,
        display: "flex",
        justifyContent: "space-between",
    },
    version: {},
    email: {
        color: "white",
        textDecoration: "none",
    },
}));

const SUPPORT_EMAIL = "cmms.support@cern.ch";

const Footer = () => {
    const classes = useStyles();
    return (
        <footer className={classes.footer}>
            <div className={classes.version}>
                {`v${process.env.REACT_APP_VERSION}`}
            </div>
            <div>
                <a
                    className={classes.email}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`mailto:${SUPPORT_EMAIL}`}
                    color="white"
                >
                    {SUPPORT_EMAIL}
                </a>
            </div>
        </footer>
    );
};

export default Footer;
