import React from "react";
import ClassicLayout from "layouts/ClassicLayout";
import { Route, useLocation } from "react-router-dom";
import queryString from "query-string";

const LayoutSelectorRoute = ({ component: Component, layoutMode, ...routeProps }) => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const computedLayoutMode = layoutMode || queryParams.layoutMode || '';
    const Layout = computedLayoutMode.toLowerCase() === 'iframe' ? React.Fragment: ClassicLayout;
    return (
        <Route
            {...routeProps}
            render={(matchProps) => (
                <Layout>
                    <Component {...matchProps} />
                </Layout>
            )}
        />
    );
};

export default LayoutSelectorRoute;
