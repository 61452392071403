import React from "react";
import {
    useRowSelect,
    useSortBy,
    useTable,
    useFilters,
    useMountedLayoutEffect,
    useFlexLayout,
} from "react-table";
import {
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
    withStyles,
    Table,
    TableSortLabel,
    CircularProgress,
} from "@material-ui/core";
import { FixedSizeList } from 'react-window'
import AutoSizer from "react-virtualized-auto-sizer";



const DefaultCellComponent = withStyles((theme) => ({
    root: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        borderRight: `1px solid ${theme.palette.grey[200]}`,
        borderTop:`1px solid ${theme.palette.grey[200]}`,
        borderBottom: 'none',
        padding: theme.spacing(1),
    }
}))(TableCell);


const DefaultTableComponent = withStyles((theme) => ({
    root: {
        borderLeft: `1px solid ${theme.palette.grey[200]}`,
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
    }
}))(Table);

const DefaultCheckbox = withStyles((theme) => ({
    root: {
        padding: 0
    }
}))(Checkbox);

const defaultPropsGetter = () => ({});

const AMMGrid = (props) => {
    const {
        columns,
        data,
        getRowId,
        initialState,
        defaultColumn,
        filterTypes,
        selectable,
        disableFilters,
        disableMultiSort,
        manualFilters,
        manualSortBy,
        autoResetSortBy,
        autoResetFilters,
        autoResetSelectedRows,
        useControlledState,
        onTableStateChange,
        getRowProps = defaultPropsGetter,
        getCellProps = defaultPropsGetter,
        getColumnProps = defaultPropsGetter,
        TableComponent = DefaultTableComponent,
        CellComponent = DefaultCellComponent,
        children,
        loading,
    } = props;

    
    
    const tableInstance = useTable(
        {
            columns,
            data,
            getRowId,
            defaultColumn,
            disableFilters,
            filterTypes,
            initialState,
            manualFilters,
            manualSortBy,
            manualPagination: true,
            disableMultiSort,
            autoResetSortBy,
            autoResetFilters,
            autoResetSelectedRows,
            ...useControlledState && { useControlledState },
        },
        useFilters,
        useSortBy,
        useRowSelect,
        useFlexLayout,
        hooks => {
            hooks.visibleColumns.push(columns => {
                if (selectable) {
                    return [
                       {
                           id: "selection",
                           Header: ({ getToggleAllRowsSelectedProps }) => (
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                   <DefaultCheckbox
                                       color="primary"
                                       style={{ display: 'table-cell' }}
                                       {...getToggleAllRowsSelectedProps()}
                                       />
                               </div>
                           ),
                           Cell: ({ row }) => (
                               <div>
                                   <DefaultCheckbox
                                       color="primary"
                                       {...row.getToggleRowSelectedProps()}
                                       />
                               </div>
                           ),
                           Filter: null,
                           filter: null,
                           disableSortBy: true,
                           width: '',
                           minWidth: 0,
                           maxWidth: 150,
                       },
                        ...columns
                   ]
                }   
                return columns;
            })
        }
    );

    const {
        state: { sortBy, filters },
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        selectedFlatRows,
    } = tableInstance;

    useMountedLayoutEffect(() => {
        onTableStateChange({ sortBy, filters, selectedRows: selectedFlatRows.map(d => d.original) })
   }, [sortBy, filters, selectedFlatRows])

   const RenderRow = React.useCallback(
    ({ index, style }) => {
        const row = rows[index]
        prepareRow(row)
        const customRowProps = getRowProps(row);

        return (
            <TableRow {...row.getRowProps({ ...customRowProps, style: { ...style, ...customRowProps.style } })} component="div" className="tr">
                {row.cells.map((cell) => {
                    const cellProps = [
                        { style: { maxWidth: cell.column.maxWidth, width: cell.column.width }},
                        getCellProps(cell),
                    ].filter(Boolean);
                    return (
                        <CellComponent {...cell.getCellProps(cellProps)} component="div" className="td">
                            {cell.render("Cell")}
                        </CellComponent>
                    )
                }
                )}
            </TableRow>
        )},
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getCellProps, getRowProps, prepareRow, rows, selectedFlatRows]
    )

    const tableRender = React.useMemo(() => {
        return loading ? (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress/>
            </div>
        )
        : (

            <TableContainer style={{ height: '100%', overflowY: 'hidden' }}>
                <TableComponent stickyHeader {...getTableProps({ style: { height: '100%' } })} component="div">
                    <TableHead component="div" style={{ display: 'block' }}>
                        {headerGroups.map((headerGroup) => (
                            <TableRow {...headerGroup.getHeaderGroupProps({ style: { overflowY: 'scroll' } })} component="div">
                                {headerGroup.headers.map((column) => {
                                    const headerProps = [
                                        { style: { maxWidth: column.maxWidth, width: column.width }},
                                        getColumnProps(column),
                                    ].filter(Boolean)
                                    return (
                                        <CellComponent key={column.id} {...column.getHeaderProps(headerProps)} component="div">   
                                            <div {...column.getSortByToggleProps()}>
                                                {column.render("Header")}
                                                {column.id !== 'selection' ? (
                                                    <TableSortLabel
                                                        active={column.isSorted}
                                                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                                                    />
                                                    ) : null}
                                            </div>
                                            {column.id !== 'selection' &&
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                }}>{column.canFilter ? column.render('Filter') : null}</div>
                                            }
                                        </CellComponent>
                                    )
                                }
                                )}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()} component="div" style={{ height: '100%', display: 'table-row' }}>
                        <div style={{ display: 'block', height: '100%' }}>
                            <AutoSizer>
                                {({ height, width }) => (
                                    <FixedSizeList
                                    height={height}
                                    itemCount={rows.length}
                                    itemSize={41}
                                    width={width}
                                    style={{ overflowY: 'scroll' }}
                                    >
                                    {RenderRow}
                                </FixedSizeList>
                                )}
                            </AutoSizer>
                        </div>
                    </TableBody>
                </TableComponent>
            </TableContainer>
        )}
    , [RenderRow, getColumnProps, getTableBodyProps, getTableProps, headerGroups, loading, rows.length]);

    return children
        ? children({
            tableInstance,
            tableRender,
        })
        : tableRender;
};

export default AMMGrid;
