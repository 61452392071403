import React from "react";
import { Link } from "react-router-dom";
import { Typography, makeStyles, Chip, Container, Box } from "@material-ui/core";
import { ROUTES } from "routes/Routes";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flex: "1 0 auto",
        justifyContent: "center",
    },
    welcomeMessage: {
        textAlign: "center",
    },
    actionButton: {
        margin: theme.spacing(2),
    },
    buttonsSection: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        "& > *": {
            margin: theme.spacing(0.5),
        },
        marginTop: theme.spacing(5),
    },
}));

const printButtons = [
    {
        route: ROUTES.print.assets,
        text: "Assets",
    },
    {
        route: ROUTES.print.positions,
        text: "Positions",
    },
    {
        route: ROUTES.print.parts,
        text: "Parts",
    },
    {
        route: ROUTES.print.systems,
        text: "Systems",
    },
    {
        route: ROUTES.print.bins,
        text: "Bins",
    },
];

const WelcomePage = () => {
    const classes = useStyles();
    return (
        <Container disableGutters fixed className={classes.root}>
            <Box component="section" m={2} >
                <section className={classes.welcomeMessage}>
                    <Typography variant="h4">
                        Welcome to EAM Barcoding
                    </Typography>
                    <Typography variant="subtitle1" />
                </section>
                <section className={classes.buttonsSection}>
                    {printButtons.map((button) => (
                        <Link
                            key={button.route}
                            to={button.route}
                            style={{ textDecoration: "none" }}
                        >
                            <Chip
                                variant="outlined"
                                color="primary"
                                label={button.text}
                                clickable
                            />
                        </Link>
                    ))}
                </section>
            </Box>
        </Container>
    );
};

export default WelcomePage;
