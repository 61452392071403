import { backendClient as client } from "api/clients";

const RESOURCE_PATH = "/grids";

const getMetadata = async ({ id, config = {} } = {}) => {
    // TODO
    throw Error('Not implemented')
}

const getDataspies = async ({ id, config = {} } = {}) => {
    // TODO
    throw Error('Not implemented')
}

const getData = async ({ gridRequest, config = {} } = {}) => client.post(`${RESOURCE_PATH}/data`, gridRequest, config);

const exportData = async ({ gridRequest, config = {} } = {}) => {
    // TODO
    throw Error('Not implemented')
}

const GET_DATA = 'grids/GET_DATA';

export default { 
    getMetadata,
    getDataspies,
    getData,
    exportData,
    GET_DATA
};
