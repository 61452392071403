import React, { createContext } from "react";
import UsersAPI from "api/users";
import { useQuery } from "react-query";

export const UserDataContext = createContext({});

export const UserDataProvider = (props) => {
    const { isLoading, error, data, isError } = useQuery(UsersAPI.GET_ME, UsersAPI.getMe);    

    const userDataContext = {
        userData: data?.body?.data,
        isFetching: isLoading,
        userDataError: error,
        isUserDataError: isError,
    };

    return (
        <UserDataContext.Provider value={userDataContext}>
            {props.children}
        </UserDataContext.Provider>
    );
};

export const { Consumer } = UserDataContext;
