import { ROUTES } from "routes/Routes"

// OSEQGN_XEP: Equipment Generations grid

export const TEMPLATE_ENTITY = {
    'OSOBJA': 'Equipment',
    'OSOBJP': 'Equipment',
    'OSOBJS': 'Equipment',
    'OSEQGN_XEP': 'Equipment',
    'SSPART': 'Part',
    'SUBING': 'Bin',
}

export const GRID_NAME_ROUTE_MAP = {
    [ROUTES.print.assets]: 'OSOBJA',
    [ROUTES.print.positions]: 'OSOBJP',
    [ROUTES.print.systems]: 'OSOBJS',
    [ROUTES.print.parts]: 'SSPART',
    [ROUTES.print.bins]: 'SUBING',
}

export const GRID_CODE_KEY = {
    'OSOBJA': 'equipmentno',
    'OSOBJP': 'equipmentno',
    'OSOBJS': 'equipmentno',
    'SSPART': 'partcode',
    'SUBING': 'bin_code',
    'OSEQGN_XEP': 'equipmentno',
    'OSOA11': 'equipmentno',
}
