import React from 'react'
import ErrorIcon from '@material-ui/icons/Error'
import { makeStyles, Typography } from "@material-ui/core"


const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
    text: {
        textAlign: "center"
    }
})

const ErrorPage = (props) => {
    const classes = useStyles();
    const { errors } = props;
    return (
        <div className={classes.root}>
            <ErrorIcon color="error" />
            {errors.map(error => (
                <Typography>
                    {/* // TODO */JSON.stringify(error)}
                </Typography>
            ))}
        </div>
    )
}

export default ErrorPage
