import queryString from "query-string";

const ROUTES = {
    homepage: "/",
    print: {
        assets: "/print/assets",
        positions: "/print/positions",
        parts: "/print/parts",
        systems: "/print/systems",
        bins: "/print/bins",
        grid: "/print/grid",
    },
};

const getRoute = (path, params = {}, queryParams = {}) =>
    withQueryParams(withParams(path, params), queryParams);

const withParams = (path, params) =>
    Object.keys(params).reduce((acc, key) => {
        const paramRegex = new RegExp(`:${key}`, "g");
        return acc.replace(paramRegex, params[key]);
    }, path);

const withQueryParams = (path, queryParams) =>
    queryString.stringifyUrl({ url: path, query: queryParams });

export { ROUTES, getRoute };
