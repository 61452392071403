import React, { useContext } from "react";
import { UserDataContext } from "contexts/UserDataContext";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import {
    Typography,
    IconButton,
    makeStyles,
    CircularProgress,
    withStyles,
} from "@material-ui/core";
import { logoutHandler } from "AuthWrapper";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
    },
    userIcon: {
        paddingRight: theme.spacing(),
    },
    logoutIcon: {},
    iconButton: {
        color: "white",
    },
    separator: {
        borderLeft: "1px solid white",
        width: 1,
        height: 22,
        marginLeft: 14,
    },
}));



const CustomCircularProgress = withStyles(() => ({
    colorPrimary: {
      color: "white"
    },
}))(CircularProgress);

const UserMenu = () => {
    const classes = useStyles();
    const { userData, isFetching } = useContext(UserDataContext);
    if (isFetching) return <CustomCircularProgress size={18} />;
    if (!userData) return null;

    return (
        <div className={classes.root}>
            <AccountCircleIcon className={classes.userIcon} />
            <Typography color="inherit" variant="subtitle2">
                {userData.userCode}
            </Typography>
            <span className={classes.separator} />
            <IconButton className={classes.iconButton} onClick={logoutHandler}>
                <LogoutIcon fontSize="small" className={classes.logoutIcon} />
            </IconButton>
        </div>
    );
};

export default UserMenu;
