import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import UserMenu from "components/Nav/UserMenu";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AppSettingsContext } from "contexts/AppSettingsContext";

const useStyles = makeStyles({
    toolbar: {
        justifyContent: "space-between",
    },
    logoHeader: {
        display: "flex",
        flexDirection: "column",
    },
    logoTitle: {
        fontSize: 18,
        fontWeight: "bold",
    },
    logoEnv: {
        fontSize: 12,
    }
});

const Nav = () => {
    const classes = useStyles();
    const { appSettings } = useContext(AppSettingsContext);
    return (
        <AppBar position="static">
            <Toolbar variant="dense" className={classes.toolbar}>
                <Link
                    to="/"
                    style={{ textDecoration: "none", color: "white" }}>
                    <section className={classes.logoHeader}>
                        <span className={classes.logoTitle}>EAM Barcoding</span>
                        {appSettings.environment !== 'PROD' && <span className={classes.logoEnv}>{appSettings.environment}</span>}
                    </section>
                </Link>
                <UserMenu />
            </Toolbar>
        </AppBar>
    );
};

export default Nav;
