import { backendClient as client } from "api/clients";

const print = async ({ printRequest, config = {} } = {}) => {
    const response = await client.post(`/print`, printRequest, config);
    return response.body.data;
}

const printAll = async ({ printAllRequest, config = {} } = {}) => {
    const response = await client.post(`/print/all`, printAllRequest, config);
    return response.body.data;
}

export default {
    print,
    printAll,
};
