import React from "react";
import { Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
        flex: "1 0 auto",
        justifyContent: "center",
    },
});

const NotFoundPage = () => {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" className={classes.root}>
            <span>Ups! 404 Page Not Found</span>
        </Container>
    );
};

export default NotFoundPage;
